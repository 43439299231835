/* ------------------------------------------------------------------------------
*
*  # Navbar component
*
*  Overrides for navbar bootstrap component
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */


// Basic styles
// -------------------------

// Base
.navbar {
    margin-bottom: 0;
    border-width: 1px 0;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: @grid-float-breakpoint) {
        padding-left: @navbar-padding-horizontal;
        padding-right: @navbar-padding-horizontal;
    }
}

// Navbar as a stand alone component
.navbar-component {
    border-radius: @border-radius-base;
    margin-bottom: @line-height-computed;
    border-width: 1px;

    // Inside page header
    .page-header & {
        margin-left: @grid-gutter-width;
        margin-right: @grid-gutter-width;
    }

    // Default navbar
    &.navbar-default {
        border-color: @panel-default-border;
        background-color: @panel-bg;
    }

    // First child border radius
    > .navbar:first-child,
    > .navbar-collapse:first-child > .navbar:first-child {
        .border-top-radius(@border-radius-base);
    }

    // Last child border radius
    > .navbar:last-child,
    > .navbar-collapse:last-child > .navbar:last-child {
        .border-bottom-radius(@border-radius-base);
    }
}

// Navbar with affix component
.affix {
    &.navbar,
    &.navbar-collapse {
        z-index: @zindex-navbar-fixed;
        top: 0;
        width: 100%;

        // Make it static on mobile
        @media (max-width: @grid-float-breakpoint-max) {
            position: static;
        }
    }
}


// Navbar header
// -------------------------

.navbar-header {
    min-width: @sidebar-base-width;

    // Inside nav collapse
    .navbar-collapse > & {
        margin-left: 0;
    }

    // Mobile nav
    .navbar-nav {
        float: right;
        margin-right: (@navbar-padding-horizontal - @content-padding-base);

        > li {
            float: left;

            > a {
                padding-left: @content-padding-base;
                padding-right: @content-padding-base;
            }
        }
    }


    // Mobile view setup
    @media (max-width: @grid-float-breakpoint-max) {
        .navbar-nav > li + li {
            margin-top: 0;
        }

        .navbar-collapse > & {
            margin-left: 0;
        }
    }

    // Desktop view setup
    @media (min-width: @grid-float-breakpoint) {
        margin-left: -(@navbar-padding-horizontal);
    }
}


// Navbar collapse (body)
// -------------------------

.navbar-collapse {
    text-align: center; // Make elements centered on mobiles
    padding-left: 0;
    padding-right: 0;

    // Remove animation since it's buggy on mobile
    &.collapsing {
        .transition-duration(0.00000001s);
    }

    // Remove top border in second navbar
    .navbar + & {
        border-top: 0;
    }

    // Desktop view setup
    @media (min-width: @grid-float-breakpoint) {
        text-align: left;
        margin-left: -(@navbar-padding-horizontal);
    }
}


// Fixed navbar setup
// -------------------------

// Make navbars static on mobile
@media (max-width: @grid-float-breakpoint-max) {
    .navbar-fixed-top {
        position: static;
    }
}


// Fixed navbar vertical spacing
@media (min-width: @grid-float-breakpoint) {

    //
    // Single navbar
    //

    // Top
    .navbar-top-lg {
        padding-top: @navbar-height-large + 2;

        // Sticky fixed sidebar
        .sidebar-fixed.affix {
            top: @navbar-height-large + @line-height-computed + 2;
        }
    }
    .navbar-top {
        padding-top: @navbar-height + 2;

        // Sticky fixed sidebar
        .sidebar-fixed.affix {
            top: @navbar-height + @line-height-computed + 2;
        }
    }
    .navbar-top-sm {
        padding-top: @navbar-height-small + 2;

        // Sticky fixed sidebar
        .sidebar-fixed.affix {
            top: @navbar-height-small + @line-height-computed + 2;
        }
    }
    .navbar-top-xs {
        padding-top: @navbar-height-mini + 2;

        // Sticky fixed sidebar
        .sidebar-fixed.affix {
            top: @navbar-height-mini + @line-height-computed + 2;
        }
    }


    //
    // Multiple navbars
    //

    // Top navbars. Double navbar height
    .navbar-top-lg-lg {
        padding-top: @navbar-height-large + @navbar-height-large + 4;
    }
    .navbar-top-lg-md,
    .navbar-top-md-lg {
        padding-top: @navbar-height + @navbar-height-large + 4;
    }
    .navbar-top-lg-sm,
    .navbar-top-md-md,
    .navbar-top-sm-lg {
        padding-top: @navbar-height + @navbar-height + 4;
    }
    .navbar-top-lg-xs,
    .navbar-top-md-sm,
    .navbar-top-sm-md,
    .navbar-top-xs-lg {
        padding-top: @navbar-height + @navbar-height-small + 4;
    }
    .navbar-top-md-xs,
    .navbar-top-sm-sm,
    .navbar-top-xs-md {
        padding-top: @navbar-height + @navbar-height-mini + 4;
    }
    .navbar-top-sm-xs,
    .navbar-top-xs-sm {
        padding-top: @navbar-height-small + @navbar-height-mini + 4;
    }
    .navbar-top-xs-xs {
        padding-top: @navbar-height-mini + @navbar-height-mini + 4;
    }
}

// Bottom
.navbar-bottom-lg {
    padding-bottom: @navbar-height-large + 2;
}
.navbar-bottom {
    padding-bottom: @navbar-height + 2;
}
.navbar-bottom-sm {
    padding-bottom: @navbar-height-small + 2;
}
.navbar-bottom-xs {
    padding-bottom: @navbar-height-mini + 2;
}

// Bottom navbars. Double navbar height vertical borders
.navbar-bottom-lg-lg {
    padding-bottom: @navbar-height-large + @navbar-height-large + 4;
}
.navbar-bottom-lg-md,
.navbar-bottom-md-lg {
    padding-bottom: @navbar-height + @navbar-height-large + 4;
}
.navbar-bottom-lg-sm,
.navbar-bottom-md-md,
.navbar-bottom-sm-lg {
    padding-bottom: @navbar-height + @navbar-height + 4;
}
.navbar-bottom-lg-xs,
.navbar-bottom-md-sm,
.navbar-bottom-sm-md,
.navbar-bottom-xs-lg {
    padding-bottom: @navbar-height + @navbar-height-small + 4;
}
.navbar-bottom-md-xs,
.navbar-bottom-sm-sm,
.navbar-bottom-xs-md {
    padding-bottom: @navbar-height + @navbar-height-mini + 4;
}
.navbar-bottom-sm-xs,
.navbar-bottom-xs-sm {
    padding-bottom: @navbar-height-small + @navbar-height-mini + 4;
}
.navbar-bottom-xs-xs {
    padding-bottom: @navbar-height-mini + @navbar-height-mini + 4;
}


// Brand/project name
// -------------------------

.navbar-brand {

    // Logo, change according to your logo size
    > img {
        margin-top: 2px;
        height: 16px;
    }
}


// Navbar nav links
// -------------------------

.navbar-nav {
    margin: 0;
    text-align: left;

    // Links
    > li > a {
        padding-top: @navbar-padding-vertical;
        padding-bottom: @navbar-padding-vertical;


        //
        // Labels and badges
        //

        // Right absolute
        > .label,
        > .badge {
            position: absolute;
            top: 0;
            right: 0;
        }
        
        // Status mark
        > .status-mark {
            position: absolute;
            top: 8px;
            right: 8px;
        }
        // Left alignment
        > .status-mark-left {
            right: auto;
            left: 8px;
        }

        // Left absolute
        > .label-left,
        > .badge-left {
            right: auto;
            left: 0;
        }

        // Inline
        > .label-inline,
        > .badge-inline,
        > .status-mark-inline {
            position: static;
        }
    }

    // Language switch images
    .language-switch a > img {
        position: relative;
        top: -1px;
    }

    // User dropdown
    > .dropdown-user {
        > a {

            // Set vertical padding
            &,
            > span {
                padding-top: (@navbar-padding-vertical / 2);
                padding-bottom: (@navbar-padding-vertical / 2);
            }

            // User name
            > span {
                display: inline-block;
                padding-left: @element-horizontal-spacing;
            }
        }

        // User image
        img {
            max-height: (@navbar-padding-vertical * 2) + 4;
            margin-top: -((@navbar-padding-vertical / 2) - 4);
            border-radius: 50%;

            // Change image size in large navbar
            .navbar-lg & {
                max-height: (@navbar-padding-vertical-large * 2) + 4;
                margin-top: -((@navbar-padding-vertical-large / 2) - 4);
            }

            // Change image size in small navbar
            .navbar-sm & {
                max-height: (@navbar-padding-vertical-small * 2) + 4;
                margin-top: -((@navbar-padding-vertical-small / 2) - 4);
            }

            // Change image size in mini navbar
            .navbar-xs & {
                max-height: (@navbar-padding-vertical-mini * 2) + 4;
                margin-top: -((@navbar-padding-vertical-mini / 2) - 4);
            }
        }
    }

    // Make dropdown menu static on mobile
    @media (max-width: @grid-float-breakpoint-max) {
        border-bottom: 1px solid fade(#fff, 10%);

        // Dropdowns get custom display when collapsed
        .open .dropdown-menu {

            // Make paddings identical
            > li > a,
            .dropdown-header {
                padding: (@padding-base-vertical + 1) @navbar-padding-horizontal;
            }

            // Dropdown submenu
            > .dropdown-submenu {

                // Add some space for children levels
                > ul > li {
                    > a {
                        padding-left: (@navbar-padding-horizontal * 2);
                    }

                    > ul > li {
                        > a {
                            padding-left: (@navbar-padding-horizontal * 3);
                        }
                    }
                }
            }
        }

        // Add 1px top separation
        > li + li {
            margin-top: 1px;
        }

        // Stick labels, badges and caret to the right
        > li > a {
            padding-left: @navbar-padding-horizontal;
            padding-right: @navbar-padding-horizontal;

            // Caret
            .caret {
                float: right;
                margin-top: ((@line-height-computed - @icon-font-size) / 2);
                margin-left: 5px;
            }

            // Labels and badges
            .label,
            .badge {
                position: static;
                float: right;
            }
        }

        // Caret in user dropdown
        > .dropdown-user .caret {
            margin-top: (@icon-font-size / 2);
        }

        // Add border if inside default navbar
        .navbar-default & {
            border-bottom: 1px solid @navbar-default-border;
        }

        // Remove border from the last nav
        &:last-child {
            border-bottom: 0;
        }
    }

    // Uncollapse the nav
    @media (min-width: @grid-float-breakpoint) {
        margin-left: @navbar-padding-horizontal;
    }
}


// Navbar form
// -------------------------

.navbar-form {
    padding: @navbar-padding-vertical @navbar-padding-horizontal;
    margin-left: 0;
    margin-right: 0;
    border-top: 0;
    .box-shadow(none);

    // Remove border from the last child
    &:last-child {
        border-bottom: 0;
    }

    // Form group row
    .form-group {
        @media (max-width: @grid-float-breakpoint-max) {
            margin-bottom: (@line-height-computed / 2);
        }
    }


    //
    // Elements sizing
    //

    // Small elements sizing
    .input-sm,
    .input-group-sm,
    .btn-sm,
    .select-sm,
    .uploader-sm {
        margin-top: (@input-height-base - @input-height-small) / 2;
        margin-bottom: (@input-height-base - @input-height-small) / 2;
    }
    .input-sm + .form-control-feedback {
        top: (@input-height-base - @input-height-small) / 2;
    }

    // Mini elements sizing
    .input-xs,
    .input-group-xs,
    .btn-xs,
    .select-xs,
    .uploader-xs {
        margin-top: (@input-height-base - @input-height-mini) / 2;
        margin-bottom: (@input-height-base - @input-height-mini) / 2;
    }
    .input-xs + .form-control-feedback {
        top: (@input-height-base - @input-height-mini) / 2;
    }

    // Navbar sizing vertical padding correction
    .navbar-lg & {
        .navbar-large-vertical-align(@input-height-base);
    }
    .navbar-sm & {
        .navbar-small-vertical-align(@input-height-base);
    }
    .navbar-xs & {
        .navbar-mini-vertical-align(@input-height-base);
    }


    // Checkboxes, radios, toggles
    .checkbox-switchery[class*=switchery-] {
        margin-bottom: 0;
    }
    .checkbox-inline.switchery-double {
        padding-left: 0;
    }


    // Desktop view setup
    @media (min-width: @grid-float-breakpoint) {
        padding: 0;

        // Set min width
        .form-control {
            min-width: 200px;
        }

        // Single file uploader
        .uploader {
            width: 200px;
        }

        // Add left margin to form rows
        .form-group {
            margin-left: @navbar-padding-horizontal;
        }

        // Inline controls
        .checkbox-inline,
        .radio-inline {
            margin-top: ((@input-height-base - @line-height-computed) / 2); // ((base input height - line height in px) / 2)
            margin-bottom: ((@input-height-base - @line-height-computed) / 2);
        }
    }

    // Remove margins
    @media (max-width: @grid-float-breakpoint-max) {
        margin: 0;
    }
}


// Dropdown menus
// -------------------------

// Menu position and menu carets
.navbar-nav > li > .dropdown-menu {
    margin-top: 1px;
    border-top-width: 0;

    // Media list
    .media-list {
        max-height: @navbar-collapse-max-height;
        overflow-y: auto;
    }
}

// Menu position and menu caret support for dropups via extra dropup class
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    margin-bottom: 1px;
    border-top-width: 1px;
    border-bottom-width: 0;
    .border-top-radius(@border-radius-base);
}


// Buttons in navbars
// -------------------------

.navbar-btn {
    margin-left: @navbar-padding-horizontal;

    // Remove left spacing in multiple buttons
    + & {
        margin-left: 0;
    }

    // Sizing
    &,
    .navbar-sm &.btn-sm,
    .navbar-xs &.btn-xs {
        .navbar-vertical-align(@input-height-base);
    }
    .navbar-lg & {
        .navbar-large-vertical-align(@input-height-base);
    }
    .navbar-sm &,
    .navbar-xs &.btn-sm {
        .navbar-small-vertical-align(@input-height-base);
    }
    .navbar-xs & {
        .navbar-mini-vertical-align(@input-height-base);
    }

    &.btn-sm,
    .navbar-sm &.btn-xs {
        .navbar-vertical-align(@input-height-small);
    }
    &.btn-xs,
    .navbar-lg &.btn-sm {
        .navbar-vertical-align(@input-height-mini);
    }
    .navbar-lg &.btn-xs {
        .navbar-large-vertical-align(@input-height-mini);
    }

    // Add margin to buttons on desktop
    @media (max-width: @grid-float-breakpoint-max) {
        &,
        &.btn-sm,
        &.btn-xs {
            margin: @navbar-padding-vertical @navbar-padding-horizontal;
        }

        & + & {
            margin-left: 0;
        }
    }
}


// Text in navbars
// -------------------------

.navbar-text {
    margin: 0;
    padding: @navbar-padding-vertical @navbar-padding-horizontal;

    // Last text doesn't have border
    &:last-child {
        border-bottom: 0;
    }


    //
    // Additional sizing
    //

    // Large
    .navbar-lg & {
        padding-top: ((@navbar-height-large - @line-height-computed) / 2);
        padding-bottom: ((@navbar-height-large - @line-height-computed) / 2);
    }

    // Small
    .navbar-sm & {
        padding-top: ((@navbar-height-small - @line-height-computed) / 2);
        padding-bottom: ((@navbar-height-small - @line-height-computed) / 2);
    }

    // Mini
    .navbar-xs & {
        padding-top: ((@navbar-height-mini - @line-height-computed) / 2);
        padding-bottom: ((@navbar-height-mini - @line-height-computed) / 2);
    }

    // Setup desktop view
    @media (min-width: @grid-float-breakpoint) {
        padding-right: 0;

        + .navbar-nav {
            margin-left: @content-padding-base;
        }

        .navbar-header + &:first-child {
            padding-left: 0;
        }
    }
}


// Component alignment
// -------------------------

@media (min-width: @grid-float-breakpoint) {
    .navbar-right {
        margin-right: 0;
    }
}


// Alternate navbars
// -------------------------

// Default navbar
.navbar-default {
    border-top-color: transparent;

    // Add class for darken default navbar
    &.navbar-default-secondary {
        background-color: darken(@navbar-default-bg, 1%);
    }

    // After page header content
    .page-header-content + & {
        border-top-color: @navbar-default-border;
    }

    // Bottom position
    &.navbar-fixed-bottom {
        border-top-color: @navbar-default-border;
        border-bottom-color: @navbar-default-bg;
    }

    // Add top border to the first navbar
    .navbar-fixed-bottom > &:first-child {
        border-top-color: @navbar-default-border;
    }

    // Dropdown menu items
    .navbar-nav {
        @media (max-width: @grid-float-breakpoint-max) {

            // Dropdowns get custom display when collapsed
            .open .dropdown-menu {
                color: @text-color;
                background-color: transparent;
                border-bottom: 1px solid @navbar-default-border;
            }

            // Borders in dropdowns first level only
            .open > .dropdown-menu {
                border-top: 1px solid @navbar-default-border;
            }

            // Remove bottom borders from last childs
            .open:last-child .dropdown-menu {
                border-bottom: 0;
            }
        }
    }

    // Links in navbars
    .navbar-link {
        color: @link-color;

        // Link hover state
        &:hover {
            color: @link-hover-color;
        }
    }

    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {

        // Active submenu link color in solid menu
        .dropdown-menu[class*=bg-] {

            // Make labels/badges colored
            .label,
            .badge {
                color: #fff;
                background-color: @brand-primary;
                border-color: transparent;
            }

            // Correct divider bg color
            > .divider {
                background-color: @dropdown-divider-bg;
            }

            // Submenu links
            .dropdown-submenu {
                &:hover > a,
                &:focus > a {
                    background-color: @dropdown-link-hover-bg;
                }
            }
        }

        // Remove border from table
        .dropdown-menu {
            .table-responsive {
                border-width: 0;
            }

            .dropdown-content-heading + .table-responsive {
                border-top-width: 1px;
            }
        }

        // Toggle button in alt navbars
        .navbar-text:not([data-toggle="collapse"]) {
            border-bottom: 1px solid @navbar-default-border;
        }
        > .navbar-nav > li > a {
            &:not(.collapsed),
            &:hover,
            &:focus {
                background-color: #fcfcfc;
            }
        }
    }
}


// Inverse navbar
.navbar-inverse {
    border-bottom-color: fade(#fff, 10%);
    color: @navbar-inverse-color;

    // Navbar collapse
    .navbar-collapse {
        border-color: fade(#000, 20%);
    }

    // Navbar form
    .navbar-form {
        border-color: fade(#fff, 10%);
    }

    // Nav dropdowns
    .navbar-nav {
        @media (max-width: @grid-float-breakpoint-max) {

            // Dropdowns get custom display
            .open .dropdown-menu {
                color: @navbar-inverse-link-color;
                background-color: fade(#000, 10%);
                border-bottom: 1px solid fade(#fff, 10%);

                // Muted and annotation texts
                .text-muted,
                .media-annotation {
                    color: fade(#fff, 80%)
                }

                // Linked media list colors
                .media-list-linked {
                    > li {
                        border-top-color: fade(#fff, 10%);
                    }

                    // Media link
                    .media-link {
                        color: @navbar-inverse-link-color;

                        // Hover/focus state
                        &:hover,
                        &:focus {
                            background-color: fade(#000, 10%);
                            color: @navbar-inverse-link-color;
                        }
                    }
                }

                // Links color
                a:not(.label-flat):not(.badge-flat):not(.disabled > a) {
                    color: @navbar-inverse-link-color;
                }

                // Divider
                .divider {
                    background-color: fade(#fff, 10%);
                }

                // Dropdown menu header
                > .dropdown-header {
                    color: fade(#fff, 60%);
                }

                // Dropdown submenu adaptation
                > .dropdown-submenu {
                    &:hover > a,
                    &:focus > a {
                        color: @navbar-inverse-link-hover-color;
                        background-color: @navbar-inverse-link-hover-bg;
                    }
                }
            }

            // Borders in dropdowns first level only
            .open > .dropdown-menu {
                border-top: 1px solid fade(#fff, 10%);
            }

            // Remove bottom borders from last childs
            .open:last-child .dropdown-menu {
                border-bottom: 0;
            }

            // Make labels and badges white
            .label,
            .badge {
                &,
                &:hover,
                &:focus {
                    background-color: #fff;
                    border-color: #fff;
                    color: @dropdown-link-color;
                }
            }

            // Make checkboxes and radios white
            .checker,
            .choice {
                > span {
                    border-color: #fff;
                    color: #fff;
                }
            }

            // Make tabs transparent
            .nav-tabs {
                background-color: transparent;
                border: 0;

                > li > a {
                    background-color: transparent;
                }
            }
        }
    }

    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {

        // Add darker bg to collapse
        .navbar-collapse {
            background-color: fade(#000, 5%);
        }

        // Add bottom border to navbar text
        .navbar-text {
            border-bottom: 1px solid fade(#fff, 10%);
        }

        // Change links background color
        > .navbar-nav > li > a:not(.collapsed) {
            background-color: fade(#000, 10%);
        }

        // Adapt component colors to collapsed navbar
        .dropdown-menu {

            // Links
            .media-body a,
            .table a {
                color: #fff;
            }

            // Tables
            .table-responsive {
                border-width: 0 0 1px 0;
                border-color: fade(#fff, 10%);
            }
            .dropdown-content-heading + .table-responsive {
                border-top-width: 1px;
            }
            .table {
                th,
                td {
                    border-color: fade(#fff, 10%);
                }
            }
        }

        // Make flat elements white
        .label-flat,
        .badge-flat,
        .btn-flat {
            border-color: #fff;
            color: #fff;
        }
    }
}


// Navbar sizing
// ------------------------------

// Large
.navbar-lg {
    min-height: @navbar-height-large;

    // Brand
    .navbar-brand {
        height: @navbar-height-large;
        padding-top: @navbar-padding-vertical-large;
        padding-bottom: @navbar-padding-vertical-large;
    }

    // Nav
    .navbar-nav {

        // Items
        > li > a {
            padding-top: @navbar-padding-vertical-large;
            padding-bottom: @navbar-padding-vertical-large;
        }

        // User dropdown
        > .dropdown-user {
            > a {
                &,
                > span {
                    padding-top: @navbar-padding-vertical-large / 2;
                    padding-bottom: @navbar-padding-vertical-large / 2;
                }
            }
        }
    }
}

// Small
.navbar-sm {
    min-height: @navbar-height-small;

    // Brand
    .navbar-brand {
        height: @navbar-height-small;
        padding-top: @navbar-padding-vertical-small;
        padding-bottom: @navbar-padding-vertical-small;
    }

    // Nav
    .navbar-nav {

        // Items
        > li > a {
            padding-top: @navbar-padding-vertical-small;
            padding-bottom: @navbar-padding-vertical-small;
        }

        // User dropdown
        > .dropdown-user {
            > a {
                &,
                > span {
                    padding-top: @navbar-padding-vertical-small / 2;
                    padding-bottom: @navbar-padding-vertical-small / 2;
                }
            }
        }
    }
}

// Mini
.navbar-xs {
    min-height: @navbar-height-mini;

    // Brand
    .navbar-brand {
        height: @navbar-height-mini;
        padding-top: @navbar-padding-vertical-mini;
        padding-bottom: @navbar-padding-vertical-mini;
    }

    // Nav
    .navbar-nav {

        // Items
        > li > a {
            padding-top: @navbar-padding-vertical-mini;
            padding-bottom: @navbar-padding-vertical-mini;
        }

        // User dropdown
        > .dropdown-user {
            > a {
                &,
                > span {
                    padding-top: @navbar-padding-vertical-mini / 2;
                    padding-bottom: @navbar-padding-vertical-mini / 2;
                }
            }
        }
    }
}


// Mega menu
// ------------------------------

// Base
.mega-menu {

    // Disable direct absolute positioning
    .nav & {
        position: static;
    }

    // Remove left positioning
    .dropdown-menu {
        left: auto;
    }

    // Full width menu
    @media (min-width: @grid-float-breakpoint) {
        &.mega-menu-wide > .dropdown-menu {
            left: @grid-gutter-width;
            right: @grid-gutter-width;

            .layout-boxed & {
                left: 0;
                right: 0;
            }
        }
    }
}

// Mega menu header
.dropdown-content-heading {
    padding: @content-padding-large;
    font-size: @font-size-small;
    text-transform: uppercase;
    font-weight: 500;

    // Remove top padding if body placed after padding
    & + .dropdown-menu-body {
        padding-top: 0;
    }

    // Remove top padding from the next dropdown header
    & + .dropdown-header {
        padding-top: 0!important;
    }

    // If inside usual dropdown, padding correction
    ul.dropdown-menu & {
        padding-left: @content-padding-base;
        padding-right: @content-padding-base;

        &:first-child {
            padding-top: (@content-padding-large - @list-spacing);
            padding-bottom: (@content-padding-large - @list-spacing);
        }
    }

    // Display icons list always on the right side
    .icons-list {
        float: right;
    }

    // Add top border to the table inside dropdown
    & + .table-responsive {
        @media (min-width: @grid-float-breakpoint) {
            border-top: 1px solid @dropdown-border;
        }
    }
}

// Mega menu footer
.dropdown-content-footer {
    background-color: @panel-footer-bg;
    color: @text-color;
    border-top: 1px solid @dropdown-border;
    .border-bottom-radius(@border-radius-base);

    // Links
    a {
        display: block;
        padding: @padding-base-vertical;
        text-align: center;
        color: @text-color;

        // Hover/focus states
        &:hover,
        &:focus {
            background-color: @dropdown-link-hover-bg;
        }

        // Remove top edge from the icon
        > i.display-block {
            top: 0;
        }
    }

    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {

        // In inversed navbar
        .navbar-inverse & {
            background-color: fade(#000, 10%);
            color: #fff;
            border-color: transparent;
            border-radius: 0;

            // Links should be white
            a {
                color: #fff;

                &:hover,
                &:focus {
                    background-color: fade(#000, 10%);
                }
            }
        }
    }
}

// Mega menu content
.dropdown-content-body {
    padding: @panel-body-padding;

    .dropdown-content-heading + & {
        padding-top: 0;
    }
}

// Remove vertical padding from content dropdown
.dropdown-content {
    &:not(ul) {
        padding-top: 0;
        padding-bottom: 0;
    }

    // Prevent wrap in inline form
    .form-inline {
        white-space: nowrap;
    }
}


// Mega menu list
// ------------------------------

// Base
.menu-list {
    margin: 0 0 @line-height-computed 0;
    list-style: none;
    padding: 0;
    overflow: hidden;

    @media (min-width: @screen-lg-min) {
        [class*=col-lg-] & {
            margin-bottom: (@padding-base-vertical + 1);
        }
    }

    @media (min-width: @screen-md-min) {
        [class*=col-md-] & {
            margin-bottom: (@padding-base-vertical + 1);
        }
    }

    @media (min-width: @screen-sm-min) {
        [class*=col-sm-] & {
            margin-bottom: (@padding-base-vertical + 1);
        }
    }

    @media (min-width: @screen-xs-min) {
        [class*=col-xs-] & {
            margin-bottom: (@padding-base-vertical + 1);
        }
    }

    // Children lists
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        display: none;
        left: 110%;
        width: 100%;
    }

    // Menu item
    li {
        position: relative;
        margin-top: 1px;

        &:first-child {
            margin-top: 0;
        }

        // Link
        > a {
            display: block;
            color: @dropdown-link-color;
            padding: (@padding-base-vertical + 1) @padding-base-horizontal;
            border-radius: @border-radius-base;

            &:hover,
            &:focus {
                background-color: @dropdown-link-hover-bg;
            }

            > i {
                margin-right: @content-padding-small;
            }

            > .label,
            > .badge {
                float: right;
                margin-left: @element-horizontal-spacing;
            }
        }

        // Active item
        &.active > a {
            &,
            &:hover,
            &:focus {
                color: @dropdown-link-active-color;
                background-color: @dropdown-link-active-bg;
            }
        }

        // Disabled item
        &.disabled {
            > a {
                &,
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: @dropdown-link-disabled-color;
                    cursor: @cursor-disabled;
                }

                // Mute elements
                > .label,
                > .badge,
                > img {
                    .opacity(0.8);
                }
            }
        }
    }


    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {
        li {

            // Link
            > a {
                color: #fff;

                &:hover,
                &:focus {
                    background-color: fade(#000, 10%);
                }
            }

            // Active state
            &.active > a {
                &,
                &:hover,
                &:focus {
                    background-color: fade(#000, 10%);
                }
            }

            // Disabled state
            &.disabled > a {
                &,
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: fade(#fff, 60%);
                    cursor: @cursor-disabled;
                }
            }
        }

        // List items in default navbar
        .navbar-default & {
            li {

                // Link
                > a {
                    color: @dropdown-link-color;

                    &:hover,
                    &:focus {
                        background-color: @dropdown-link-hover-bg;
                    }
                }

                // Active state
                &.active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: @dropdown-link-active-color;
                        background-color: @dropdown-link-active-bg;
                    }
                }

                // Disabled state
                &.disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: @dropdown-link-disabled-color;
                    }
                }
            }
        }
    }


    // Setup desktop view
    @media (min-width: @grid-float-breakpoint) {
        overflow-y: auto;
        max-height: @navbar-collapse-max-height;
    }
}

// Drill down menu
.dd-wrapper {

    // Menu
    .dd-menu {
        overflow: hidden;
        position: relative;
    }

    // Header
    .dd-header {
        h6 {
            .label,
            .badge {
                margin-left: @element-horizontal-spacing;
            }

            > i {
                margin-right: @element-horizontal-spacing;
            }

            &:first-child {
                display: none;
            }
        }
    }

    // Display child lists
    .dd-parent .active-ul + ul {
        position: relative;
        display: block;
        left: 0;
    }

    // "Back" link
    .link-back {
        display: block;
        padding: (@padding-base-vertical + 1) 0;
        border-radius: @border-radius-base;
        margin-bottom: (@line-height-computed / 2);
        font-size: @font-size-small;
        text-transform: uppercase;
        line-height: @line-height-small;

        // Arrow icon
        &:before {
            content: '\ede7';
            font-family: 'icomoon';
            font-size: @icon-font-size;
            line-height: 1;
            position: relative;
            top: -1px;
            vertical-align: middle;
            margin-right: @element-horizontal-spacing;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        // Hide default icon and use custom instead
        .dd-icon,
        i {
            display: none;
        }


        // Setup mobile view
        @media (max-width: @grid-float-breakpoint-max) {

            // Base and default navbar
            .navbar & {
                padding: @line-height-computed 0;
                text-align: center;
                margin-top: -(@line-height-computed / 2);
                margin-bottom: @line-height-computed;
                border-bottom: 1px solid @gray-lighter;
            }

            // Inverse navbar
            .navbar-inverse & {
                color: #fff;
                border-bottom-color: fade(#fff, 10%);
            }
        }
    }

    // Arrow icons
    .dd-icon {
        float: right;
        margin-top: ((@line-height-computed - @icon-font-size) / 2);

        // "Right" arrow and base
        &:after {
            content: '\e9c7';
            font-family: 'icomoon';
            font-size: @icon-font-size;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    // Use another icon in header
    .dd-header .dd-icon:after {
        content: '\e9c5';
    }
}

// Mega menu list heading
.menu-heading {
    display: block;
    font-size: @font-size-small;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: (@line-height-computed / 2);
    padding-top: ((@line-height-computed - @font-size-small) / 2);

    // Icons
    > i {
        float: left;
        margin-right: @element-horizontal-spacing;
    }

    // Underlined heading
    &.underlined {
        padding-bottom: (@line-height-computed / 2);
        border-bottom: 1px solid @gray-lighter;
    }


    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {
        color: #fff;

        // Remove top margin in the first item
        div:first-child > & {
            margin-top: 0;
        }

        // In default navbar
        .navbar-default & {
            color: @dropdown-link-color;
        }

        // Underlined
        &.underlined {
            border-bottom-color: fade(#fff, 10%);

            .navbar-default & {
                border-bottom-color: @gray-lighter;
            }
        }
    }
}


// Tabs inside navbar dropdowns
// ------------------------------

.dropdown-menu {
    .nav-tabs {

        // Remove border radius
        &,
        > li > a {
            border-radius: 0;
        }

        // Remove side borders from first and last tabs
        > li,
        > li.active {
            &:first-child > a {
                border-left: 0;
            }

            &:last-child > a {
                border-right: 0;
            }
        }


        // Setup mobile view
        @media (max-width: @grid-float-breakpoint-max) {
            margin-top: 0;

            // Remove helper label
            &:before {
                content: none;
            }

            // List item
            > li {

                // Add 1px gap between items
                & + li {
                    margin-top: 1px;
                }

                // Link
                > a {
                    border: 0;
                    padding-left: @navbar-padding-horizontal;
                    padding-right: @navbar-padding-horizontal;

                    &:hover,
                    &:focus {
                        background-color: fade(#000, 10%)
                    }
                }

                // Active state
                &.active {
                    > a,
                    > a:hover,
                    > a:focus {
                        border: 0;
                        background-color: fade(#000, 10%);
                    }
                }
            }

            // Common navbar styles
            .navbar & {
                border-top: 1px solid fade(#fff, 10%);
                border-bottom: 1px solid fade(#fff, 10%);

                > li {
                    margin-bottom: 0;
                }

                &.active > a {
                    border-color: transparent;
                }
            }

            // Inverse navbar
            .navbar-inverse & {
                > li {
                    > a {
                        color: #fff;
                    }

                    // Disabled state
                    &.disabled > a {
                        &,
                        &:hover,
                        &:focus {
                            color: fade(#fff, 60%);
                            background-color: transparent;
                        }
                    }
                }
            }

            // Default navbar
            .navbar-default & {
                border-top-color: @navbar-default-border;
                border-bottom-color: @navbar-default-border;
                border-left: 0;
                border-right: 0;

                // List item
                > li {

                    // Links hover state
                    > a:hover,
                    > a:focus {
                        color: @navbar-default-color;
                        background-color: @navbar-default-link-hover-bg;
                    }

                    // Active state
                    &.active > a {
                        &,
                        &:hover,
                        &:focus {
                            color: @navbar-default-color;
                            background-color: @navbar-default-link-hover-bg;
                        }
                    }

                    // Disabled state
                    &.disabled > a {
                        &,
                        &:hover,
                        &:focus {
                            color: @navbar-default-link-disabled-color;
                            background-color: @navbar-default-link-disabled-bg;
                        }
                    }
                }
            }
        }
    }
}


// Navbar progress bars
// ------------------------------

// Base
.navbar-progress {
    float: left;
    margin-left: @navbar-padding-horizontal;

    // Progress bar
    .progress {
        width: 200px;
    }

    // Setup mobile view
    @media (max-width: @grid-float-breakpoint-max) {
        margin: @navbar-padding-vertical @navbar-padding-horizontal;
        float: none;

        .progress {
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
        }
    }
}

// Sizing
.navbar-xs .navbar-progress .progress {
    .navbar-mini-vertical-align(@progress-base-height);
}
.navbar-progress .progress,
.navbar-xs .navbar-progress .progress-sm {
    .navbar-vertical-align(@progress-base-height);
}
.navbar-progress .progress-sm,
.navbar-xs .navbar-progress .progress-xs {
    .navbar-mini-vertical-align(@progress-mini-height);
}
.navbar-progress .progress-xs,
.navbar-xs .navbar-progress .progress-xxs {
    .navbar-mini-vertical-align(@progress-tiny-height);
}
.navbar-progress .progress-xxs {
    .navbar-vertical-align(@progress-tiny-height);
}
.navbar-sm .navbar-progress .progress {
    .navbar-small-vertical-align(@progress-base-height);
}
.navbar-lg .navbar-progress .progress,
.navbar-sm .navbar-progress .progress-sm {
    .navbar-large-vertical-align(@progress-base-height);
}
.navbar-lg .navbar-progress .progress-sm,
.navbar-sm .navbar-progress .progress-xs {
    .navbar-large-vertical-align(@progress-small-height);
}
.navbar-lg .navbar-progress .progress-xs,
.navbar-sm .navbar-progress .progress-xxs {
    .navbar-large-vertical-align(@progress-mini-height);
}
.navbar-lg .navbar-progress .progress-xxs {
    .navbar-large-vertical-align(@progress-tiny-height);
}


// Navbar inner dropdowns on mobiles
// ------------------------------

@media (max-width: @grid-float-breakpoint-max) {

    // Common navbar dropdown
    .navbar {
        .btn-group,
        .dropdown,
        .input-group {

            // Make dropdown full width with no borders
            .dropdown-menu {
                width: 100%;
                border-width: 0 0 1px 0;
                border-radius: 0;

                > li > a {
                    padding-left: @navbar-padding-horizontal;
                    padding-right: @navbar-padding-horizontal;
                }
            }
        }

        // Remove relative position from dropdown parents
        .btn-group,
        .input-group,
        .form-group:not(.has-feedback),
        .input-group-btn {
            position: static;
        }

        // Make select2 select full width
        .select2-container {
            width: 100%!important;
        }
    }

    // Bottom fixed navbar
    .navbar-fixed-bottom {
        .btn-group,
        .dropdown,
        .input-group {
            .dropdown-menu {
                border-width: 1px 0 0 0;
            }
        }
    }

    // Revert for navbar component
    .navbar-component {
        .btn-group,
        .dropdown,
        .input-group {
            .dropdown-menu {
                border-width: 0 1px 1px 1px;
                .border-bottom-radius(@border-radius-base);
            }
        }
    }

    // Remove bottom spacing from tables
    .table-responsive {
        margin-bottom: 0;
    }
}
