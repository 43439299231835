/* ------------------------------------------------------------------------------
*
*  # Button group component
*
*  Overrides for button group bootstrap component
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */


// Base
// -------------------------

// Prevent double borders when buttons are next to each other
.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: 1px;
    }

    // In default button border is darker than bg
    .btn + .btn-default,
    .btn-default + .btn-group,
    .btn-group + .btn-default {
        margin-left: -1px;
    }
}

// Group multiple button groups together for a toolbar
.btn-toolbar {
    font-size: 0;
    
    .btn-group,
    .input-group {
        float: none;
    }
}

// Remove left border radius in multiple buttons
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    .border-left-radius(0);
}


// Sizing
// -------------------------

// Since we use overrides in buttons.less, sizing needs to be
// dublicated here
.btn-group-xlg > .btn {
    &:extend(.btn-xlg);
}
.btn-group-lg > .btn {
    &:extend(.btn-lg);
}
.btn-group-sm > .btn {
    &:extend(.btn-sm);
}
.btn-group-xs > .btn {
    &:extend(.btn-xs);
}


// Caret button size in split buttons
// ----------------------

// Default button and button group
.btn-group > .btn + .dropdown-toggle {
    padding-left: (@padding-base-horizontal - 3);
    padding-right: (@padding-base-horizontal - 3);
}

// XLarge button and button group
.btn-group > .btn-xlg + .dropdown-toggle,
.btn-group-xlg > .btn + .dropdown-toggle {
    padding-left: (@padding-xlarge-horizontal - 3);
    padding-right: (@padding-xlarge-horizontal - 3);
}

// Large button and button group
.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg > .btn + .dropdown-toggle {
    padding-left: (@padding-large-horizontal - 3);
    padding-right: (@padding-large-horizontal - 3);
}

// Small button and button group
.btn-group > .btn-sm + .dropdown-toggle,
.btn-group-sm > .btn + .dropdown-toggle {
    padding-left: (@padding-small-horizontal - 3);
    padding-right: (@padding-small-horizontal - 3);
}

// Mini button and button group
.btn-group > .btn-xs + .dropdown-toggle,
.btn-group-xs > .btn + .dropdown-toggle {
    padding-left: (@padding-xs-horizontal - 3);
    padding-right: (@padding-xs-horizontal - 3);
}


// The clickable button for toggling the menu
// ----------------------

.btn-group.open .dropdown-toggle {
    .box-shadow(0 0 0 100px fade(#000, 10%) inset);

    &.btn-default {
        .box-shadow(0 0 0 100px fade(#000, 3%) inset);
    }
}


// Justified button groups
// ----------------------

.btn-group-justified {
    > .btn + .btn,
    > .btn-group + .btn-group > .btn {
        border-left-color: fade(#fff, 20%);
    }

    > .btn + .btn-default,
    > .btn-group + .btn-group > .btn-default {
        border-left-width: 0;
    }
}
